export { matchers } from './client-matchers.js';

export const components = [
	() => import("../../src/routes/__layout.svelte"),
	() => import("../../src/routes/__error.svelte"),
	() => import("../../src/routes/account/__layout.svelte"),
	() => import("../../src/routes/account/chats/__layout.svelte"),
	() => import("../../src/routes/account/services/__layout.svelte"),
	() => import("../../src/routes/checkout/__layout.svelte"),
	() => import("../../src/routes/recover/__layout.svelte"),
	() => import("../../src/routes/[username]/index.svelte"),
	() => import("../../src/routes/about.svelte"),
	() => import("../../src/routes/account/benefits/index.svelte"),
	() => import("../../src/routes/account/chats/index.svelte"),
	() => import("../../src/routes/account/courses/assign/[id].svelte"),
	() => import("../../src/routes/account/courses/edit/[id].svelte"),
	() => import("../../src/routes/account/courses/index.svelte"),
	() => import("../../src/routes/account/courses/new/index.svelte"),
	() => import("../../src/routes/account/courses/new/success.svelte"),
	() => import("../../src/routes/account/index.svelte"),
	() => import("../../src/routes/account/profile/changepassword.svelte"),
	() => import("../../src/routes/account/profile/edit/highlighted-links.svelte"),
	() => import("../../src/routes/account/profile/edit/interests.svelte"),
	() => import("../../src/routes/account/profile/edit/links.svelte"),
	() => import("../../src/routes/account/profile/edit/presentation.svelte"),
	() => import("../../src/routes/account/profile/index.svelte"),
	() => import("../../src/routes/account/services/edit/[id].svelte"),
	() => import("../../src/routes/account/services/index.svelte"),
	() => import("../../src/routes/account/services/new/index.svelte"),
	() => import("../../src/routes/account/services/new/success.svelte"),
	() => import("../../src/routes/account/settings/address/[id].svelte"),
	() => import("../../src/routes/account/settings/address/index.svelte"),
	() => import("../../src/routes/account/settings/address/new.svelte"),
	() => import("../../src/routes/account/settings/edit/address.svelte"),
	() => import("../../src/routes/account/settings/edit/bank.svelte"),
	() => import("../../src/routes/account/settings/index.svelte"),
	() => import("../../src/routes/account/store/index.svelte"),
	() => import("../../src/routes/auth/experts/index.svelte"),
	() => import("../../src/routes/auth/experts/signup/index.svelte"),
	() => import("../../src/routes/auth/experts/signup/success.svelte"),
	() => import("../../src/routes/auth/experts/signup/update.svelte"),
	() => import("../../src/routes/auth/index.svelte"),
	() => import("../../src/routes/auth/users/index.svelte"),
	() => import("../../src/routes/auth/users/signup/index.svelte"),
	() => import("../../src/routes/auth/users/signup/success.svelte"),
	() => import("../../src/routes/auth/users/signup/update.svelte"),
	() => import("../../src/routes/cart.svelte"),
	() => import("../../src/routes/categories/[slug]/experts.svelte"),
	() => import("../../src/routes/categories/[slug]/index.svelte"),
	() => import("../../src/routes/checkout/address.svelte"),
	() => import("../../src/routes/courses/[id]/index.svelte"),
	() => import("../../src/routes/courses/[id]/register/error.svelte"),
	() => import("../../src/routes/courses/[id]/register/index.svelte"),
	() => import("../../src/routes/courses/[id]/register/success.svelte"),
	() => import("../../src/routes/courses/index.svelte"),
	() => import("../../src/routes/endpoints/error/verify/invalid-token.svelte"),
	() => import("../../src/routes/faq.svelte"),
	() => import("../../src/routes/index.svelte"),
	() => import("../../src/routes/legal.svelte"),
	() => import("../../src/routes/onboarding/error.svelte"),
	() => import("../../src/routes/onboarding/index.svelte"),
	() => import("../../src/routes/premium.svelte"),
	() => import("../../src/routes/products/[slug].svelte"),
	() => import("../../src/routes/recover/index.svelte"),
	() => import("../../src/routes/recover/reset.svelte"),
	() => import("../../src/routes/recover/sent.svelte"),
	() => import("../../src/routes/services/[id]/buy/error.svelte"),
	() => import("../../src/routes/services/[id]/buy/index.svelte"),
	() => import("../../src/routes/services/[id]/buy/success.svelte"),
	() => import("../../src/routes/services/[id]/index.svelte"),
	() => import("../../src/routes/services/index.svelte")
];

export const dictionary = {
	"": [[0, 54], [1]],
	"about": [[0, 8], [1]],
	"account": [[0, 2, 16], [1]],
	"auth": [[0, 38], [1]],
	"cart": [[0, 43], [1]],
	"courses": [[0, 51], [1]],
	"faq": [[0, 53], [1]],
	"legal": [[0, 55], [1]],
	"onboarding": [[0, 57], [1]],
	"premium": [[0, 58], [1]],
	"recover": [[0, 6, 60], [1]],
	"services": [[0, 67], [1]],
	"account/benefits": [[0, 2, 9], [1]],
	"account/chats": [[0, 2, 3, 10], [1]],
	"account/courses": [[0, 2, 13], [1]],
	"account/profile": [[0, 2, 22], [1]],
	"account/services": [[0, 2, 4, 24], [1]],
	"account/settings": [[0, 2, 32], [1]],
	"account/store": [[0, 2, 33], [1]],
	"auth/experts": [[0, 34], [1]],
	"auth/users": [[0, 39], [1]],
	"checkout/address": [[0, 5, 46], [1]],
	"onboarding/error": [[0, 56], [1]],
	"recover/reset": [[0, 6, 61], [1]],
	"recover/sent": [[0, 6, 62], [1]],
	"account/courses/new": [[0, 2, 14], [1]],
	"account/profile/changepassword": [[0, 2, 17], [1]],
	"account/services/new": [[0, 2, 4, 25], [1]],
	"account/settings/address": [[0, 2, 28], [1]],
	"auth/experts/signup": [[0, 35], [1]],
	"auth/users/signup": [[0, 40], [1]],
	"account/courses/new/success": [[0, 2, 15], [1]],
	"account/profile/edit/highlighted-links": [[0, 2, 18], [1]],
	"account/profile/edit/interests": [[0, 2, 19], [1]],
	"account/profile/edit/links": [[0, 2, 20], [1]],
	"account/profile/edit/presentation": [[0, 2, 21], [1]],
	"account/services/new/success": [[0, 2, 4, 26], [1]],
	"account/settings/address/new": [[0, 2, 29], [1]],
	"account/settings/edit/address": [[0, 2, 30], [1]],
	"account/settings/edit/bank": [[0, 2, 31], [1]],
	"auth/experts/signup/success": [[0, 36], [1]],
	"auth/experts/signup/update": [[0, 37], [1]],
	"auth/users/signup/success": [[0, 41], [1]],
	"auth/users/signup/update": [[0, 42], [1]],
	"endpoints/error/verify/invalid-token": [[0, 52], [1]],
	"account/courses/assign/[id]": [[0, 2, 11], [1]],
	"account/courses/edit/[id]": [[0, 2, 12], [1]],
	"account/services/edit/[id]": [[0, 2, 4, 23], [1]],
	"account/settings/address/[id]": [[0, 2, 27], [1]],
	"categories/[slug]": [[0, 45], [1]],
	"courses/[id]": [[0, 47], [1]],
	"products/[slug]": [[0, 59], [1]],
	"services/[id]": [[0, 66], [1]],
	"categories/[slug]/experts": [[0, 44], [1]],
	"courses/[id]/register": [[0, 49], [1]],
	"services/[id]/buy": [[0, 64], [1]],
	"courses/[id]/register/error": [[0, 48], [1]],
	"courses/[id]/register/success": [[0, 50], [1]],
	"services/[id]/buy/error": [[0, 63], [1]],
	"services/[id]/buy/success": [[0, 65], [1]],
	"[username]": [[0, 7], [1]]
};